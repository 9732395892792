import { connect } from 'react-redux';

import { getIdToken } from 'componentlibrary';
import MicroFrontend from '../../Components/MicroFrontend';

const mapStateToProps = (state, ownProps) => ({
  microFrontend: state.microFrontend,
  permission: state.auth.permission,
  currentLanguage: state.auth.currentLanguage,
  idToken: state.auth.idToken,
  auth: state.auth,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getIdToken(...args) {
    return getIdToken(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MicroFrontend);
